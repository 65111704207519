import $ from 'jquery';
import Vue from 'vue';
import * as Cookies from 'js-cookie';
import {mapActions, mapGetters} from 'vuex';
import {store} from './store';
import jQuery from 'jquery';
window.jQuery = window.$ = jQuery;



export default Vue.mixin({
  mixins: [],
  computed: {
    ...mapGetters(['getSchoolName', 'getFavouriteCollege']),
    getIsLanguagesExempted: function() {
      return this.$store.state.exemptLanguages;
    },
    getIsIrishExempted: function() {
      return this.$store.state.exemptIrish;
    },
    noSchoolCollegeInfo: function() {
      return (this.getSchoolName === '' || this.getFavouriteCollege === '') &&
          this.showDialogue && this.$store.state.userLoggedIn;
    },
    getCareer: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        return data.yooni_fit.career;
      return '';
    },
    getTestStatus: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        return data.yooni_fit.status;
      return 0;
    },
    getPersonality: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        return data.personality;
      return '';
    },
    getCourseFit: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        return data.yooni_fit.course_fit;
      return '';

    },
    getCourseStatus: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        return data.subjects.status;
      return 0;
    },
    getCollegeLocation: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined) {
        // this.collegeLocation = data.college_location;
        return data.college_location;
      }
      return [0, 0];
    },
    getCourseData: function() {
      let data = this.$store.state.selectedCourseData[0];
      if (data !== undefined)
        this.courseData = data;
      return this.courseData;
    },
    getYoutubeVideoId: function() {
      if (this.courseData.vidurl !== undefined) {
        let videoId;
        if (this.courseData.vidurl.includes('watch')) {
          let splitedURL = this.courseData.vidurl.split('watch?v=');
          videoId = splitedURL[1];
          let url = splitedURL[0];
          this.courseData.vidurl = url + '/embed/' + videoId + '/';
        }
        if (this.courseData.vidurl.includes('vimeo.com/')) {
          let splitedURL = this.courseData.vidurl.split('vimeo.com/');
          videoId = splitedURL[1];
          this.courseData.vidurl = 'https://player.vimeo.com/video/' +
              videoId + '/';
        }
      }
      return this.courseData.vidurl;
    },
    getIsPremiumUser: function() {
      return this.$store.state.isPremiumUser;
    },
    getRouteName: function() {
      return this.$route.name.replace(/\b\w/g, l => l.toUpperCase());
    },
    getIsAdmin: function() {
      return this.$store.state.isAdmin;
    },
    getIsCounsellor: function() {
      return this.$store.state.userType.toString() === '2';
    },
    getUserLoggedIn: function() {
      return this.$store.getters.getUserLoggedIn;
    },
    getLoadingScreenStatus: function() {
      return this.$store.state.loadingScreen;
    },
  },
  ...mapActions(['setUserLoggedIn']),
  mounted() {
    if (this.getAuthToken()) {
      store.state.userLoggedIn = true;
    }

  },
  data: function() {
    return {
      publicPath: process.env.BASE_URL,
      courseFilters: [],
    };
  },
  methods: {
    ...mapActions([
      'setUserLoggedOut',
      'setUserLoggedIn',
      'setPredictedCAOPoints',
      'setCareerTestResults',
      'setPersonalityTestResults',
      'setRecommendations',
      'setSelectedCourseData',
      'setSchoolName',
      'setFavouriteCollege',
      'setSearchedCourses',
      'setSearchedCourseType',
      'setSearchedCourseName',
      'setSearchedCollegeName',
      'setSearchedProvinceName',
      'setSearchedSectorName',
      'setShortlistedCourses',
      'setCAOFormCourses',
      'setExemptLanguages',
      'setExemptIrish',
      'setIsAdmin',
      'setStudentFullName',
      'setStudentEmail',
      'setSortType',
      'setFeedbackGiven',
      'setRecommendationsGenerated',
      'setUserType',
      'setUserId',
      'setSchoolCounsellorAccess',
      'setPrivateCounsellorAccess',
      'setCurrentSchoolYear',
    ]),
    ...mapGetters([
      'getSearchedCourses',
      'getSearchedCourseName',
      'getSearchedCollegeName',
      'getSearchedProvinceName',
      'getSearchedSectorName',
      'getShortlistedCourses',
      'getSearchedLevel',
      'getSortType',
    ]),
    isProduction() {
      return process.env.NODE_ENV === 'production';
    },
    courseShortlisted: function(courseId, level) {
      if (level !== undefined) {
        if (level.toString() === '7' || level.toString() === '6')
          level = '6_7';
        let shortlistedCourses = this.getShortlistedCourses()[level.toString()];
        if (shortlistedCourses !== undefined) {
          let filteredData = shortlistedCourses.filter(function(element) {
            return element.code === courseId;
          });
          return filteredData.length !== 0;
        }
      }
      return false;
    },
    shortlistCourse: function(course, action) {
      let self = this;
      let url;
      if (action === 'add') {
        self.sendAnalyticsEvents('course_shortlist', course.code);
        url = self.getBaseUrl() + '/keywords/shortlist/add/';
      } else {
        url = self.getBaseUrl() + '/keywords/shortlist/remove/';
      }
      let level = '6_7';
      if (course.level.toString() === '8')
        level = '8';
      $.ajax({
        beforeSend: function(request) {
          request.setRequestHeader('Authorization',
              'Token ' + self.getAuthToken());
        },
        url: url,
        type: 'POST',
        data: {'courses': course.code, 'level': level},
        success: function(response) {
          self.setShortlistedCourses(response['shortlisted_courses']);
        },
        error: function() {
        },
      });
    },
    getShortlistedCourse: function(studentId = 0) {
      let self = this;
      let url = self.getBaseUrl() + `/keywords/shortlist/list/${studentId}/`;
      return new Promise((resolve, reject) => {
        $.ajax({
          url: url,
          type: 'GET',
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          success: function(response) {
            self.setShortlistedCourses(response['sc']);
            // self.$store.state.loadingScreen = false;
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    getSelectedFilters: function(tags = false) {
      let tempArr = [];
      this.$nextTick(function() {
        // if (this.getSearchedCourseName() !== '') {
        //   if (tags) {
        //     tempArr.push(
        //         {name: 'Course Name', value: this.getSearchedCourseName()});
        //   } else {
        //     tempArr.push(this.getSearchedCourseName());
        //   }
        // }
        if (this.getSearchedCollegeName() !== '') {
          if (tags) {
            tempArr.push(
                {name: 'College Name', value: this.getSearchedCollegeName()});
          } else {
            tempArr.push(this.getSearchedCollegeName());
          }
        }
        if (this.getSearchedProvinceName() !== '') {
          if (tags) {
            tempArr.push(
                {name: 'Province Name', value: this.getSearchedProvinceName()});
          } else {
            tempArr.push(this.getSearchedProvinceName());
          }
        }
        if (this.getSearchedSectorName() !== '') {
          if (tags) {
            tempArr.push(
                {name: 'Sector Name', 'value': this.getSearchedSectorName()});
          } else {
            tempArr.push(this.getSearchedSectorName());
          }
        }
        if (this.getSearchedLevel() !== '') {
          if (tags) {
            tempArr.push(
                {name: 'Level', 'value': this.getSearchedLevel()});
          } else {
            tempArr.push(this.getSearchedLevel());
          }
        }
        if (!tags) {
          this.courseFilters = tempArr.join(', ');
        }
        this.courseFilters = tempArr;
      });
    },
    fetchCourses: function() {
      let self = this;
      // self.$store.state.loadingScreen = true;
      self.loadingScreen = true;
      let sortMapping = {
        'Default': 4,
        'Course Name(A-Z)': 0,
        'Course Name(Z-A)': 1,
        'CAO Points(Low-High)': 2,
        'CAO Points(High-Low)': 3,
      };
      const queryString = this.$route.query;
      let requestQuery = [];

      if (queryString['type'] !== undefined) {
        self.courseType = queryString['type'];
        requestQuery.push('type=' + self.courseType);
      }
      if (queryString['course'] !== undefined) {
        self.courseName = queryString['course'];
        this.sendAnalyticsEvents('course_search', self.courseName);
        requestQuery.push('course=' + self.courseName);
      }
      if (queryString['college'] !== undefined) {
        self.collegeName = queryString['college'];
        this.sendAnalyticsEvents('college_search', self.collegeName);
        requestQuery.push('college=' + self.collegeName);
      }
      if (queryString['province'] !== undefined) {
        self.provinceName = queryString['province'];
        this.sendAnalyticsEvents('province_search', self.provinceName);
        requestQuery.push('province=' + self.provinceName);
      }
      if (queryString['sector'] !== undefined) {
        self.sectorName = queryString['sector'];
        this.sendAnalyticsEvents('sector_search', self.sectorName);
        requestQuery.push('sector=' + self.sectorName);
      }
      if (queryString['level'] !== undefined) {
        self.courseLevel = queryString['level'];
        this.sendAnalyticsEvents('level_search', self.courseLevel);
        requestQuery.push('level=' + self.courseLevel);
      }
      if (queryString['mn'] !== undefined)
        requestQuery.push('mn=' + queryString['mn']);
      else
        requestQuery.push('mn=' + 0);
      if (queryString['mx'] !== undefined)
        requestQuery.push('mx=' + queryString['mx']);
      else
        requestQuery.push('mx=' + 625);

      requestQuery.push('page=' + self.$store.state.currentPageNumber);
      requestQuery.push('st=' + sortMapping[self.$store.state.sortType]);
      self.setSearchedCourseType(self.courseType);
      self.setSearchedCourseName(self.courseName);
      self.setSearchedCollegeName(self.collegeName);
      self.setSearchedProvinceName(self.provinceName);
      self.setSearchedSectorName(self.sectorName);
      return new Promise((resolve, reject) => {
        requestQuery = requestQuery.join('&');
        self.getCourseDataBackend(requestQuery).then(response => {
          response;
          resolve(true);
        }).catch(error => {
          error;
          reject(false);
        });
      });
    },
    getCourseDataBackend: function(requestQuery) {
      let self = this;
      let url;
      // self.$store.state.loadingScreen = true;
      self.loadingScreen = true;
      if (requestQuery.includes(self.getBaseUrl())) {
        url = requestQuery;
      } else {
        if (self.$store.state.isAdmin) {
          url = self.getBaseUrl() + '/keywords/admin/search_course?' +
              requestQuery;
        } else {
          url = self.getBaseUrl() + '/keywords/search_course?' + requestQuery;
        }
      }
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken()) {
              request.setRequestHeader(
                  'Authorization',
                  'Token ' + self.getAuthToken(),
              );
            }
          },
          url: url,
          type: 'GET',
          success: function(response) {
            if (response.results !== undefined) {
              self.$store.state.searchedCourses = response.results;
              self.$store.state.totalSearchCount = response.count;
              self.$store.state.nextPage = response.next;
              self.$store.state.previousPage = response.previous;
            } else {
              self.$store.state.searchedCourses = response;
            }
            // self.$store.state.searchedCoursePages = response.pages;
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    checkToken: function(data, provider, fromPayment) {
      return new Promise((resolve, reject) => {
        let self = this;
        let url = `${self.getBaseUrl()}/api/check/`;
        $.ajax({
          url: url,
          type: 'POST',
          data: {
            'provider': provider,
            'token': localStorage.getItem('vue-authenticate.vueauth_token'),
          },
          success: function(response) {
            self.setLoginToken({
              token: data['token'],
            });
            if (!fromPayment)
              resolve(response);
          },
          error: function() {
            reject();
          },
        });
      });
    },
    async authenticate(provider, kwargs, fromPayment = false) {
      let self = this;
      try {
        let response = await self.$auth.authenticate(provider, kwargs);
        
        await self.checkToken(response['data'], provider, fromPayment);
        
        self.$store.state.loadingScreen = true;
        
        await self.getSchoolCollege();
        await self.getColleges();
        await self.getSchools();
    
        self.$store.state.loadingScreen = false;
        self.$router.push('/');
        self.checkUserLoggedIn();
        self.getUserInfo();
        
      } catch (error) {
        console.error("Authentication Error:", error);
        self.$store.state.loadingScreen = false;
      }
    },
    showPaymentWall: function() {
      this.$router.push({'name': 'PaymentWall'});
    },
    fetchSubjects: function(from = 'home') {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/subjects/list/';
      if (self.getAuthToken() !== '') {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          type: 'GET',
          success: function(response) {
            if (response.length > 0) {
              self.$nextTick(function() {
                self.selectedSubjectsList = response;
                if (from === 'points') {
                  self.updatedSubjectOptions = response;
                  self.updateSubjectList();
                }
              });
            } else {
              if (from === 'home')
                self.fillEmptySubjects(self);
              else
                self.selectedSubjectsList = [
                  {
                    'subject_name': '',
                    'level': '',
                    'grade': '',
                  }];
            }
          },
          error: function() {
            self.fillEmptySubjects(self);
          },
        });
      } else {
        self.fillEmptySubjects(self);
      }
    },
    fillEmptySubjects: function(self) {
      let temp = {
        'subject_name': 'Subject Title',
        'grade': 'H1',
        'level': 'HIGHER',
      };
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
      self.selectedSubjectsList.push(temp);
    },
    getPredictedCAOPoints: function(studentId = 0) {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/get_predict_cao_points/' +
          studentId;
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          type: 'GET',
          success: function(response) {
            if (response.predicted_points !== undefined && response.predicted_points > 0) {
              self.pointsPredicted = true;
              self.setPredictedCAOPoints(response.predicted_points);
              resolve(true);
            } else {
              resolve(false);
            }
          },
          error: function() {
            self.pointsPredicted = false;
            reject(false);
          },
        });
      });

    },
    getCareerTestResults: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/test_results_1/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          type: 'GET',
          success: function(response) {
            self.setCareerTestResults(response);
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    getPersonalityTestResults: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/test_results_2/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url,
          type: 'GET',
          success: function(response) {
            self.setPersonalityTestResults(response);
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });

    },
    generateRecommendations: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/generate/1/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken(),
            );
          },
          url,
          type: 'GET',
          success: function(response) {
            response;
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    getRecommendations: function() {
      let self = this;
      let url = self.getBaseUrl() + '/recommendations/final_result/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken(),
            );
          },
          url,
          type: 'GET',
          success: function(response) {
            self.setRecommendations(response);
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    getCourseDetails: function(courseId) {
      let self = this;
      let url = self.getBaseUrl() + `/keywords/course/${courseId}/`;

      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken()) {
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken(),
              );
            }
          },
          url,
          type: 'GET',
          success: function(response) {
            resolve(response);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent);
    },
    getResolution: function(percentage) {
      return percentage * screen['height'] / 100 + 'px';
    },
    notifyVue: function(message, type) {
      /**
       * Global function for notifcation
       * @param: {message} Message to display in the notification label
       * @param: {type} Type of the notification, danger, success, warning, info.
       * */
      this.$message({
        title: type,
        message: message.replace(/"/g, '').replace(/{/g, '').replace(/}/g, ''),
        type: type,
        duration: 10000,
        showClose: true,
      });
    },
    getSchoolCollege: function() {
      let self = this;
      return new Promise((resolve, reject) => {
        let url = self.getBaseUrl() + '/keywords/user_info/list/';
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'GET',
          success: function(data) {
            self.setSchoolName(data.school_name);
            self.setFavouriteCollege(data.college_name);
            self.setSchoolCounsellorAccess(data.school_counsellor_access);
            self.setPrivateCounsellorAccess(data.private_guidance_counsellor);
            self.setCurrentSchoolYear(data.year);
            resolve(data);
          },
          error: function() {
            self.$store.state.loadingScreen = false;
            reject();
          },
        });
      });
    },
    getUserInfo: function() {
      return new Promise((resolve, reject) => {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/info/';
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'GET',
          success: function(data) {
            if (data['status'] === 0) {
              Cookies.remove('authtoken');
              self.setUserLoggedOut();
              self.setNonUserIntercom();
            } else {
              let name = data.name.split(' ');
              self.$store.state.firstName = name[0];
              if (name.length > 0) {
                self.$store.state.lastName = name[1];
              }
              self.setStudentFullName(data.name);
              self.setStudentEmail(data.email);
              self.setUserId(data.id);
              self.setNonUserIntercom();
              window.intercomSettings = {
                app_id: 'yq63yd2f',
                name: data.name, // Full name
                email: data.email, // Email address
              };
            }
            resolve(true);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    checkUserLoggedIn: function() {
      let self = this;
      // self.$store.state.loadingScreen = true;
      return new Promise((resolve, reject) => {
        let url = self.getBaseUrl() + '/accounts/logged_in/';
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'GET',
          success: function(data) {
            if (data['status'] === 0) {
              Cookies.remove('authtoken');
              self.setUserLoggedOut();
              self.setNonUserIntercom();
            } else {
              self.$store.state.isPremiumUser = data.user_type.toString() ===
                  '1';
              self.setExemptLanguages(data.exempt_languages);
              self.setExemptIrish(data.exempt_irish);
              self.setIsAdmin(data.is_admin);

              self.setFeedbackGiven(data.feedback_given);
              self.setRecommendationsGenerated(data.recommendations_generated);
              self.setUserType(data.user_type);
            }
            self.$store.state.loadingScreen = false;
            resolve(data);
          },
          error: function() {
            Cookies.remove('authtoken');
            self.setUserLoggedOut();
            window.intercomSettings = {};
            self.setNonUserIntercom();
            self.$router.push('/');
            self.$store.state.loadingScreen = false;
            reject(false);
          },
        });
      });
    },
    setLoginToken: function(data) {
      const cookieTimeStamp = Math.floor(Date.now() / 1000);
      Cookies.set('authtoken', {
            token: data.token,
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            timestamp: cookieTimeStamp,
          },
          {
            secure: false, // Change this to true before commit
          });
      this.setUserLoggedIn(true);
    },
    getAuthToken: function() {
      try {
        return Cookies.getJSON('authtoken').token;
      } catch (e) {
        return '';
        // this.$router.push('/login');
      }
    },
    getBaseUrl: function() {
      let baseUrl = '';
      if (process.env.NODE_ENV === 'development') {
        baseUrl = window.location.protocol + '//' + window.location.hostname +
            ':8000';
      } else {
        baseUrl = window.location.origin;
      }
      baseUrl = 'https://puv57ml807.execute-api.eu-west-1.amazonaws.com/dev';
      // baseUrl = 'http://student.skillsvista.com';
      return baseUrl;
    },
    performLogin: function(fromPayment = false, amount = undefined) {
      let self = this;
      self.$store.state.loadingScreen = true;
      this.login(fromPayment, amount).then(response => {
        let redirectURL = self.$route.query.redirect;
        self.setIsAdmin(response.is_staff);
        self.$store.state.isPremiumUser = response.user_type.toString() === '1';
        if (response.user_type.toString() === '2') {
          self.$router.push('/counsellor/home/');
        } else if (self.$store.state.isAdmin) {
          self.$router.push('/admin/');
        } else {
          self.$router.push(redirectURL || '/');
        }
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    createAccount: function(fromPayment = false, amount = undefined) {
      return new Promise((resolve, reject) => {
        let self = this;
        self.errorsFullName = '';
        self.errorsEmail = '';
        self.errorsPassword = '';
        self.errorsConfirmPassword = '';
        let url = self.getBaseUrl() + '/accounts/create/';

        if (self.fullName === '') {
          self.errorsFullName = 'Name cannot be empty';
          reject(false);
        }
        if (self.emailId === '') {
          self.errorsEmail = 'Email Id cannot be empty';
          reject(false);
        }
        if (self.password === '') {
          self.errorsPassword = 'Password cannot be empty';
          reject(false);
        }
        if (self.password !== self.confirmPassword) {
          self.errorsConfirmPassword = 'Passwords do not match';
          reject(false);
        }
        let temp = self.fullName.split(' ');
        let firstName = temp[0];
        let lastName = '';
        if (temp.length > 1)
          lastName = temp[1];
        let data = {
          'email': self.emailId,
          'password': self.password,
          'password2': self.confirmPassword,
          'first_name': firstName,
          'last_name': lastName,
          'ref_code': self.referralCode,
        };

        $.ajax({
          url: url,
          type: 'POST',
          data: data,
          success: function(response) {
            response;
            // self.setLoginToken(response);
            // self.$router.push('/');
            self.$analytics.fbq.event('CompleteRegistration', {
              name: self.fullName,
              email: self.emailId,
            });
            self.login(fromPayment, amount).then(response1 => {
              resolve(response1);
            }).catch(error => {
              error;
              self.$store.state.loadingScreen = false;
            });
          },
          error: function(error) {
            self.mainError = JSON.parse(error.responseText);
            self.$store.state.loadingScreen = false;
            reject(false);
            // self.$message({
            //   title: 'Unsuccessful',
            //   message: 'Account creation failed. Please try again later',
            //   type: 'error',
            //   duration: 3000,
            //   showClose: true,
            // });
          },
        });
      });
    },
    login: function(fromPayment = false, amount = undefined) {
      let self = this;
      let url = self.getBaseUrl() + '/accounts/login/';
      return new Promise((resolve, reject) => {
        $.ajax({
          url,
          type: 'POST',
          contentType: 'application/json',
          data: JSON.stringify({
            'email': self.emailId,
            'password': self.password,
            'username': self.emailId,
            'amount': amount,
          }),
          success: function(response) {
            self.$message({
              title: 'Successful',
              message: 'Login successful',
              type: 'success',
              duration: 3000,
              showClose: true,
            });
            self.setLoginToken(response);
            self.$store.state.firstName = response.first_name;
            self.$store.state.lastName = response.last_name;

            self.setStudentFullName(
                response.first_name + ' ' + response.last_name);
            self.$store.state.isPremiumUser = response.user_type.toString() ===
                '1';
            self.$store.state.loadingScreen = true;
            self.getSchoolCollege().then(data => {
              self.$store.state.loadingScreen = true;
              self.getColleges().then(data => {
                self.$store.state.loadingScreen = true;
                self.getSchools().then(data => {
                  let redirectURL = self.$route.query.redirect;
                  if (redirectURL === '/payment/') {
                    if (!self.$store.state.isPremiumUser) {
                      if (!fromPayment) {
                        self.$router.push(redirectURL);
                      } else {
                        resolve(response);
                      }
                    } else {
                      self.$router.push('/');
                    }
                  } else {
                    resolve(response);
                  }
                });
              });
            });
            // else {
            //   if (!self.$store.state.isPremiumUser) {
            //     if (!fromPayment) {
            //       self.$router.push(redirectURL || '/');
            //     } else {
            //       resolve(response);
            //     }
            //   } else {
            //     self.$router.push('/');
            //   }
            // }
          },
          error: function() {
            self.mainError = 'Incorrect Username/password';
            self.$store.state.loadingScreen = false;
            /*self.$message({
              title: 'Unsuccessful',
              message: 'Login failed',
              type: 'error',
              duration: 3000,
              showClose: true,
            });*/
          },
        });
      });
    },
    fetchFiltersList: function() {
      return new Promise(((resolve, reject) => {
        let self = this;
        $.ajax({
          url: self.getBaseUrl() + '/keywords/filters/get/',
          type: 'GET',
          // beforeSend: function(request) {
          //   request.setRequestHeader('Authorization',
          //       'Token ' + self.getAuthToken());
          // },
          success: function(response) {
            resolve(response);
            // resolve(self.dataSuccess(response));
          },
          error: function() {
            reject();
          },
        });
      }));

    },
    dataSuccess(res) {
      return res.reduce(function(accumulator, current) {
        if (checkIfAlreadyExist(current)) {
          return accumulator;
        } else {
          return accumulator.concat([current]);
        }

        function checkIfAlreadyExist(currentVal) {
          return accumulator.some(function(item) {
            return (item.title === currentVal.title);
          });
        }
      }, []);
    },
    sendAnalyticsEvents: function(eventName = 'search', eventLabel = '') {
      gtag('event', eventName,
          {
            'event_category': eventLabel,
            'event_label': eventName,
            'send_to': 'UA-134862200-4',
            'non_interaction': true,
          });
    },
    sendAnalytics: function(title = '') {
      let routeTitle = this.$route.name;
      gtag('config', 'UA-134862200-4', {
        page_title: routeTitle,
        page_location: window.location,
        non_interaction: true,
      });
    },
    setNonUserIntercom: function() {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', w.intercomSettings);
      } else {
        var d = document;
        var i = function() {
          i.c(arguments);
        };
        i.q = [];
        i.c = function(args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://widget.intercom.io/widget/yq63yd2f';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (w.attachEvent) {
          w.attachEvent('onload', l);
        } else {
          w.addEventListener('load', l, false);
        }
      }
    },
    listSchools: function() {
      let self = this;
      let url = self.getBaseUrl() + '/keywords/schools/list/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'GET',
          success: function(response) {
            resolve(response);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    listColleges: function() {
      let self = this;
      let url = self.getBaseUrl() + '/keywords/colleges/list/';
      return new Promise((resolve, reject) => {
        $.ajax({
          beforeSend: function(request) {
            request.setRequestHeader('Authorization',
                'Token ' + self.getAuthToken());
          },
          url: url,
          type: 'GET',
          success: function(response) {
            resolve(response);
          },
          error: function() {
            reject(false);
          },
        });
      });
    },
    toTitleCase: function(str) {
      return str.replace(
          /\w\S*/g,
          function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
          },
      );
    },
    getColleges: function() {
      return new Promise((resolve, reject) => {
        let self = this;
        self.listColleges().then(response => {
          self.$store.state.colleges = response.map(function(value) {
            return {'value': value['name']};
          });
          resolve(response);
        }).catch(error => {
          self.$store.state.loadingScreen = false;
          reject(false);
        });
      });
    },
    getSchools: function() {
      return new Promise((resolve, reject) => {
        let self = this;
        self.listSchools().then(response => {
          self.$store.state.schools = response;
          resolve(response);
        }).catch(error => {
          self.$store.state.loadingScreen = false;
          reject(false);
        });
      });
    },
  },
});
