import { render, staticRenderFns } from "./CollegeList.vue?vue&type=template&id=d4f55d26&scoped=true"
import script from "./CollegeList.vue?vue&type=script&lang=js"
export * from "./CollegeList.vue?vue&type=script&lang=js"
import style0 from "./CollegeList.vue?vue&type=style&index=0&id=d4f55d26&prod&lang=css"
import style1 from "./CollegeList.vue?vue&type=style&index=1&id=d4f55d26&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4f55d26",
  null
  
)

export default component.exports