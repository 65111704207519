<template>
    <div class="main-report">
        <el-row>
            <el-col v-if="level != '3_4_5'" class="yooni-fit-head-2 head-2">
                Your overall <strong>level {{getLevel()}}</strong> top 5 courses in <strong>Ireland</strong>
            </el-col>
            <el-col v-else class="yooni-fit-head-2 head-2">
                Your overall <strong>level {{getLevel()}}</strong> top courses in <strong>Ireland</strong>
            </el-col>
        </el-row>
        <el-row :gutter="20" :style="{padding: '10px'}">
            <div :key="courseName" @click="showCourseDetails(obj.id, obj.code)" class="course-div"
                 v-if="isActive(obj.id)"
                 v-for="(obj, courseName) in getTopIrelandCourses">
                <web-course-card :key="level + '-' + courseName" :collegeName="obj.college" :course-data="obj"></web-course-card>
            </div>
        </el-row>
        <el-row v-if="level != '3_4_5'" class="courseRow">
            <el-col class="yooni-fit-head-2 head-2">
                Your top 5 course fits in <strong>{{ getFilterCollegeName }}</strong>
            </el-col>
        </el-row>
        <el-row :gutter="20" :style="{'padding': '10px'}" v-if="getCourseFitData[filterGrade].length > 0 && level != '3_4_5'">
            <div :key="courseName" @click="showCourseDetails(obj.id)" class="course-div"
                 v-for="(obj, courseName) in getCourseFitData[filterGrade]">
                <web-course-card :key="level + '-' + getFilterCollegeName" :collegeName="getFilterCollegeName" :course-data="obj"></web-course-card>
            </div>
        </el-row>
        <el-row v-else-if="level != '3_4_5'">
            <div style="text-align: left; padding: 1vw 0;">
                No courses found for the selected college
            </div>
        </el-row>
        <el-row v-if="level != '3_4_5'">
            <el-col :span="12" class="yooni-fit-head-2 head-2">
                Show your top 5 courses in any college below
            </el-col>
        </el-row>
        <el-row v-if="level != '3_4_5'">
            <div :key="index" :style="collegeName === getFilterCollegeName?{border: '1px solid #9698D6 !important'}:{}"
                @click="filterByCollegeName(collegeName)"
                class="college-div"
                v-for="(collegeName, index) in getCollegeList">
                <el-card :body-style="{ padding: '0px' }" class="college-card">
                    <el-col :span="8">
                        <CollegeImage :key="level + '-' + collegeName" :collegeName="collegeName" :collegesList="true" :publicPath="publicPath" :imgStyle="{width: '100%', height: '100%', padding: '10px'}"></CollegeImage>
                    </el-col>
                    <el-col :span="16" class="card-title-2">
                        {{collegeName}}
                    </el-col>
                </el-card>
            </div>
        </el-row>
    </div>
</template>

<script>
import CollegeImage from '../../CommonComponents/CollegeImage.vue';
import recommendationMixins from '../../mixins/recommendation_mixins';
import WebCourseCard from './WebCourseCard';
export default {
    name: 'WebCourseYooniFit',
    props: ['level'],
    components: { WebCourseCard, CollegeImage},
    data: function() {
      return {
        activeCourses: {}
      };
    },
    mixins: [recommendationMixins],
    mounted() {
        this.fetchActiveCourses(this.getTopIrelandCourses);
    },
    watch: {
        getTopIrelandCourses(newCourses) {
            this.fetchActiveCourses(newCourses);
        }
    },
    methods: {
        async fetchActiveCourses(courseList) {
            let self = this;
            self.$store.state.loadingScreen = true;
            const promises = courseList.map(obj => {
                if (!obj.code) return Promise.resolve({ id: obj.id, is_active: true });

                const url = this.getBaseUrl() + '/keywords/fet/courselist/?id=' + obj.id;
                return $.ajax({
                    url: url,
                    type: 'GET',
                    beforeSend: (request) => {
                        if (this.getAuthToken()) {
                            request.setRequestHeader('Authorization', 'Token ' + this.getAuthToken());
                        }
                    }
                }).then((res) => {
                    return { id: obj.id, is_active: res.is_active };
                }).catch(() => {
                    return { id: obj.id, is_active: false };
                });
            });

            const results = await Promise.all(promises);
            results.forEach(item => {
                this.$set(this.activeCourses, item.id, item.is_active); // make reactive
            });
            self.$store.state.loadingScreen = false;
        },
        isActive(id) {
            return this.activeCourses[id]; // simple synchronous lookup
        },
        getLevel: function () {
            if (this.level.toString() == '8')
                return '8'
            else if (this.level.toString() == '6_7')
                return '6/7'
            else if (this.level.toString() == '3_4_5')
                return '3/4/5'
            return this.level
        },
        showCourseDetails: function(id, code=null) {
            if(this.$store.state.userType.toString() !== '2') {
                if (code) {
                    let url = ''
                    url = `https://www.fetchcourses.ie/course/finder?sfcw-courseId=${code}`;
                    let win = window.open(url, '_blank');
                    if (win) {
                        win.focus();
                    } else {
                        alert('Please allow popups for this website');
                    }
                }
                else {
                    this.$router.push('/course/' + id + '/');
                }
            }
        },
        getOffset: function(index) {
            if (index === 0) {
                return 0;
            }
        }
    }
}
  </script>
<style>
    .college-card > .el-card__body {
        padding: 0 !important;
        height: 100%;
    }
</style>
<style scoped>


    .card-title-2 {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: normal;
        font-size: 1vw;
        text-align: center;
        color: #000000;
        height: 100%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .yooni-fit-head-2 {
        padding: 1vw 0;
        font-family: Niramit, serif !important;
        font-style: normal;
        font-weight: 300;
        color: #000000;
        text-align: justify !important;
    }

    .head-2 {
        font-size: 1.1vw;
        line-height: 1.1vw;
    }

    .course-div {
        overflow-y: hidden;
        float: left;
        width: 20%;
        padding-right: 1vw;
        padding-bottom: 1vw;
    }

    .college-div {
        float: left;
        width: calc(33.33% - 16px);
        margin: 1.5vh 16px 1.5vh 0;
        height: 12vh;
        background-color: white !important;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
        border: 1px solid #EBEEF5;
    }

    .college-card {
        box-shadow: none !important;
        border: none !important;
        background-color: transparent;
        height: 100%;
    }

    .college-div:hover {
        border: 1px solid #9698D6 !important;
        cursor: pointer;
        transform: scale(1.01);
    }

    .course-card:hover {
        border: 1px solid #9698D6 !important;
        cursor: pointer;
        /*transform: scale(1.02);*/
    }
</style>