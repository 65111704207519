import { render, staticRenderFns } from "./sidebar.vue?vue&type=template&id=daa79766&scoped=true&style=true"
import script from "./sidebar.vue?vue&type=script&lang=js"
export * from "./sidebar.vue?vue&type=script&lang=js"
import style0 from "./sidebar.vue?vue&type=style&index=0&id=daa79766&prod&lang=css"
import style1 from "./sidebar.vue?vue&type=style&index=1&id=daa79766&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "daa79766",
  null
  
)

export default component.exports