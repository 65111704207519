<template>
    <el-card>
        <div class="overall-fit-mobile">
            <overall-star-badge :percentage="courseData.overall_fit + '%'"></overall-star-badge>
        </div>
        <CollegeImage v-if="courseData.code" :collegeName="collegeName" :courseType="'ETB'" :publicPath="publicPath" :className="'image'"></CollegeImage>
        <CollegeImage v-else :collegeName="collegeName" :publicPath="publicPath" :className="'image'"></CollegeImage>
        <el-row>
            <div class="card-title-1">{{courseData.title}}</div>
        </el-row>
        <el-row>
            <div class="card-desc-mobile-1">
                <el-row v-if="courseData.course_fit !== 0">
                    <el-col :span="19">
                        Academic Fit
                    </el-col>
                    <el-col :span="5">
                        {{courseData.course_fit}}%
                    </el-col>
                </el-row>
                <el-row v-if="courseData.career_fit !== 0">
                    <el-col :span="19">
                        Career Fit
                    </el-col>
                    <el-col :span="5">
                        {{courseData.career_fit}}%
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="19">
                        Personality Fit
                    </el-col>
                    <el-col :span="5">
                        {{courseData.interest_fit}}
                    </el-col>
                </el-row>

            </div>
        </el-row>
    </el-card>
</template>

<script>
  import OverallStarBadge from '../../../main_components/OverallStarBadge';
  import CollegeImage from '../../CommonComponents/CollegeImage.vue';

  export default {
    name: 'MobileCourseCard',
    props: ['courseData', 'collegeName'],
    components: {OverallStarBadge, CollegeImage},
  };
</script>

<style scoped>
    .image {
        width: 100%;
        height: 15vh;
    }

    .card-desc-mobile-1 {
        text-align: justify;
        padding: 1vh 3vw 0;
        font-size: calc(1vw + 1vh);
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        line-height: calc(2vw + 2vh);
        color: #000000;
    }

    .overall-fit-mobile {
        position: absolute;
        margin-left: 32vw;
        margin-top: -1.5vh;
        /*margin-left: 10% !important;*/
        font-family: Lato, serif;
        line-height: 2vw;
        align-items: center;
        font-weight: 500;
        color: #000000;
        width: calc(3.2vh + 3.2vw);
    }
</style>
