<template>
    <div v-if="!$store.state.loadingScreen"
         style="padding: 8px 4px; overflow-y: auto; overflow-x: hidden;"
         :style="isMobile() ? {height: '65vh'}: {height: getHeight}">
        <el-row style="display: flex; flex-direction: row; align-items: center;" v-if="getCourseList.length !== 0">
            <div style="width: 70%; ">
                <div class="summary-item-list"
                     v-if="getFilters !== ''">
                    {{$store.state.totalSearchCount}} course(s) found
                </div>
                <!--<span v-if="getSearchCourseCount > 0" class="summary-item-list">All results</span>-->
            </div>
            <div style="width: 30%; display: flex; flex-direction: row-reverse;padding-right: 12px;"
                 v-if="!isMobile()">
                <el-dropdown trigger="click" class="dropdown" placement="bottom-end" @command="handleDropdownRequest">
                    <span class="el-dropdown-link">
                        <span class="sort-by">
                             <strong>Sort by:</strong> {{activeSortType}}<i
                                class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                    </span>
                    <el-dropdown-menu slot="dropdown" class="dropdown">
                        <el-dropdown-item
                                v-for="el in dropdownElements"
                                :key="el.name"
                                v-bind:command="el.name">
                            {{el.name}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </el-row>

        <!--        <a @click="showAllResult()" class="show-all">-->
        <!--            <u>Show All Courses</u>-->
        <!--        </a>-->
        <DisplayList v-if="getCourseList.length !== 0" :displayList="getCourseList"/>
        <el-pagination
                style="margin-bottom: 5vh;"
                v-if="getCourseList.length !== 0"
                hide-on-single-page
                background
                layout="prev, pager, next"
                :page-size="20"
                :current-page.sync="$store.state.currentPageNumber"
                @current-change="handleCurrentChange"
                @next-click="navigateCourse($store.state.nextPage, 'next')"
                @prev-click="navigateCourse($store.state.previousPage, 'prev')"
                :total="$store.state.totalSearchCount">
        </el-pagination>
    </div>
</template>

<script>
  import DisplayList from './CourseDisplayList';
  import {mapActions} from 'vuex';

  export default {
    name: 'CourseListDesktop',
    props: ['caoRange'],
    components: {
      DisplayList,
    },
    computed: {
      getHeight: function() {
        if (this.$store.state.searchedCollegeName !== '' || this.$store.state.searchedProvinceName !== '' ||
            this.$store.state.searchedSectorName !== '')
          return 'calc(100vh - (32px + 20px + 3vh + 1vw + 4vh + 40px + 3vh + 32px))';
        return 'calc(100vh - (20px + 1vw + 4vh + 40px + 3vh + 32px))';
      },
      getCourseList: function() {
        let self = this;
        self.coursesList = this.$store.getters.getSearchedCourses;
        if (this.coursesList.every(function(element) { return 'centre_name' in element; })) {
            this.coursesList.forEach(element => {
                element.course_type = 'ETB';
                element.college_campus = element.centre_name;
                element.code = element.course_code;
                element.title = element.course_title;
                element.qualification = element.target_award_title;
                element.url = element.online_application_url;
            });
        }
        else {
            this.coursesList.forEach(element => {
                element.course_type = 'College';
            });
            self.coursesList = self.coursesList.filter(function(element) {
              return (element.cao_points >= self.caoRange[0] && element.cao_points <= self.caoRange[1]) ||
                  element.cao_points > 625;
            });
        }
        return self.coursesList;
      },
      getSearchCourseCount: function() {
        return this.coursesList.length;
      },
      getFilters: function() {
        return [this.$store.state.searchedCourseName, this.$store.state.searchedCollegeName].join(', ');
        // return this.courseFilters.join(', ');
      },
    },
    ...mapActions(['setSortType']),
    mounted() {
      let self = this;
      self.$store.state.searchedCourses = [];
      self.getSelectedFilters(false);
      self.setSortType(self.activeSortType);
    },
    data: function() {
      return {
        coursesList: [], //ICourseInfoTemplate object array,
        inputCourseArrayList: [],
        courseFilters: [],
        loadingScreen: false,
        totalSearchCount: 0,
        dropdownElements: [
          {
            name: 'Default',
          },
          {
            name: 'Course Name(A-Z)',
          },
          {
            name: 'Course Name(Z-A)',
          },
          {
            name: 'CAO Points(Low-High)',
          },
          {
            name: 'CAO Points(High-Low)',
          },
        ],
        activeSortType: 'Default',
      };
    },
    methods: {
      ...mapActions(['setSortType']),
      navigateCourse: function(page, action='next') {
        let self = this;
        self.$store.state.loadingScreen = true;
        if (action === 'next') {
          this.$store.state.currentPageNumber += 1
        } else {
          this.$store.state.currentPageNumber -= 1
        }
        this.getCourseDataBackend(page).then(response => {
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
      },
      handleCurrentChange(val) {
        let self = this;
        self.$store.state.currentPageNumber = val;
        self.$store.state.loadingScreen = true;
        self.fetchCourses().then(response => {
          self.$store.state.loadingScreen = false;
        }).catch(error => {
          self.$store.state.loadingScreen = false;
        });
      },
      queryCourseSearchAsync(queryString, cb) {
        let courselist = this.coursesArr;
        let programList = [];
        for (let i = 0; i < this.coursesArr.length; i++) {
          programList.push({
            value: courselist[i].title,
          });
        }
        let results = queryString
            ? programList.filter(this.createStateFilter(queryString))
            : programList;

        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(results);
        }, 1000 * Math.random());
      },

      showAllResult: function() {
        for (let index = 6; index < this.inputCourseArrayList.length; index++) {
          const element = this.unclutteringData(this.inputCourseArrayList[index]);
          this.$set(this.coursesList, index, element);
        }
      },

      unclutteringData(val) {
        let element = val;
        element.courseType = 'FULL TIME';
        element.courseBegins = '2021';
        element.uni = 'University of Cork';
        return element;
      },

      handleDropdownRequest: function(command) {
        let self = this;
        self.activeSortType = command;
        self.setSortType(command);
        self.$store.state.loadingScreen = true;
        self.fetchCourses().then(response => {
          setTimeout(function() {
            self.$store.state.loadingScreen = false;
          }, 2000);
        }).catch(error => {
          setTimeout(function() {
            self.$store.state.loadingScreen = false;
          }, 2000);
        });
      },
    },
  };
</script>

<style scoped>
    .sort-by-btn:active, .sort-by-btn:hover, .sort-by-btn:focus {
        background-color: white;
    }

    .summary-item-list {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        color: #000000;
        text-align: justify !important;
        padding-left: 16px;
        padding-right: 16px;
    }

    .show-all {
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 600;
        font-size: 0.8vw;
        line-height: 1.6vw;
        display: flex;
        align-items: center;
        text-decoration-line: underline;
        margin-left: 1%;
        padding-top: 1%;
        padding-bottom: 1%;
        color: #000000;
        cursor: pointer;
    }

    .el-dropdown-link {
        /*padding: 10px;*/
        width: 100%;
        /*background: #ffffff;*/
        /*box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);*/
    }

    .el-dropdown-menu {
        background-color: white;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }
</style>
