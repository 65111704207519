<template>
    <el-card :style="{ padding: '16px' }" class="course-card">
        <div class="overall-fit">
            <overall-star-badge :percentage="courseData.overall_fit + '%'"></overall-star-badge>
        </div>
        <CollegeImage v-if="courseData.code" :collegeName="collegeName" :courseType="'ETB'" :publicPath="publicPath" :className="'image'"></CollegeImage>
        <CollegeImage v-else :collegeName="collegeName" :publicPath="publicPath" :className="'image'"></CollegeImage>
        <el-row style="height: 4.4vh;">
            <div class="card-title-1">{{courseData.title}}</div>
        </el-row>
        <el-row>
            <div class="card-desc-1">
                <el-row v-if="courseData.course_fit !== 0">
                    <el-col :span="20">
                        Academic Fit
                    </el-col>
                    <el-col :span="4">
                        {{courseData.course_fit}}%
                    </el-col>
                </el-row>

                <el-row v-if="courseData.career_fit !== 0">
                    <el-col :span="20">
                        Career Fit
                    </el-col>
                    <el-col :span="4">
                        {{courseData.career_fit}}%
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="20">
                        Personality Fit
                    </el-col>
                    <el-col :span="4">
                        {{courseData.interest_fit}}
                    </el-col>
                </el-row>
            </div>
        </el-row>
    </el-card>
</template>

<script>
  import OverallStarBadge from '../../../main_components/OverallStarBadge';
  import CollegeImage from '../../CommonComponents/CollegeImage.vue';

  export default {
    name: 'WebCourseCard',
    props: ['courseData', 'collegeName'],
    components: {OverallStarBadge, CollegeImage},
  };
</script>

<style scoped>
    .image {
        width: 100%;
        padding: 1vh;
        height: 15vh;
    }

    .card-desc-1 {
        text-align: justify;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        line-height: calc(1vw + 1vh);
        color: #000000;
    }

    .overall-fit {
        position: absolute;
        margin-left: 12vw;
        margin-top: -0.9vw;
        /*margin-left: 10% !important;*/
        font-family: Lato, serif;
        line-height: 2vw;
        align-items: center;
        font-weight: 500;
        color: #000000;
        width: calc(1vh + 1vw);
    }

</style>