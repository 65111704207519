<template>
    <div class="main-report" style="background: transparent;">
        <el-row :gutter="20" :style="{'padding': '10px'}">
            <el-col class="yooni-fit-head-mobile-2 head-mobile-2">
                <span>
                    Your overall top 5 courses in Ireland
                </span>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="main-class">
            <div :key="courseName" @click="showCourseDetails(obj.id, obj.code)" class="course-div-mobile"
                 v-if="isActive(obj.id)"
                 v-for="(obj, courseName) in getTopIrelandCourses">
                <mobile-course-card :key="level + '-' + getFilterCollegeName" :collegeName="obj.college" :course-data="obj"></mobile-course-card>
            </div>
        </el-row>
        <el-row v-if="level != '3_4_5'" class="courseRow" :gutter="20" :style="{'padding': '10px'}">
            <el-col class="yooni-fit-head-mobile-2 head-mobile-2">
                <span>
                    Your top 5 course fits in {{getFilterCollegeName}}
                </span>
            </el-col>
        </el-row>
        <el-row :gutter="20" class="main-class" v-if="getCourseFitData[filterGrade].length > 0 && level != '3_4_5'">
            <div :key="courseName" @click="showCourseDetails(obj.id)" class="course-div-mobile"
                 v-for="(obj, courseName) in getCourseFitData[filterGrade]">
                <mobile-course-card :key="level + '-' + getFilterCollegeName" :collegeName="getFilterCollegeName" :course-data="obj"></mobile-course-card>
            </div>
        </el-row>
        <el-row v-else-if="level != '3_4_5'">
            <div style="text-align: left;padding: 1vw;">
                No courses found for the selected college
            </div>
        </el-row>
        <el-row v-if="level != '3_4_5'" :style="{'padding': '10px'}">
            <el-col class="yooni-fit-head-mobile-2 head-mobile-2">
                <span>
                    Show your top 5 courses in any college below
                </span>
            </el-col>
        </el-row>
        <el-row v-if="level != '3_4_5'" :gutter="20" :style="{'padding': '0 10px'}"
                style="padding: 0 !important;">
            <div :key="index" @click="filterByCollegeName(collegeName)"
                 class="college-div-mobile" v-for="(collegeName, index) in getCollegeList">

                <el-card class="college-card" style="height: 12vh;">
                    <el-col :span="8">
                        <CollegeImage :key="level + '-' + collegeName" :collegeName="collegeName" :publicPath="publicPath" 
                            :imgStyle="{ height: '100%', width: '100%' }"></CollegeImage>
                    </el-col>
                    <el-col :span="16" class="college-card-title">
                        {{collegeName}}
                    </el-col>
                </el-card>
            </div>
        </el-row>
    </div>
</template>

<script>
  import recommendationMixins from '../../mixins/recommendation_mixins';
  import OverallStarBadge from '../../../main_components/OverallStarBadge';
  import MobileCourseCard from './MobileCourseCard';
  import CollegeImage from '../../CommonComponents/CollegeImage.vue';

  export default {
    components: {MobileCourseCard, OverallStarBadge, CollegeImage},
    mixins: [recommendationMixins],
    name: 'MobileCourseYooniFit',
    props: ['level'],
    data: function() {
        return {
            loadingScreen: false,
            activeCourses: {}
        };
    },
    mounted() {
        this.fetchActiveCourses(this.getTopIrelandCourses);
    },
    watch: {
        getTopIrelandCourses(newCourses) {
            this.fetchActiveCourses(newCourses);
        }
    },
    methods: {
        async fetchActiveCourses(courseList) {
            let self = this;
            self.$store.state.loadingScreen = true;
            const promises = courseList.map(obj => {
                if (!obj.code) return Promise.resolve({ id: obj.id, is_active: true });

                const url = this.getBaseUrl() + '/keywords/fet/courselist/?id=' + obj.id;
                return $.ajax({
                    url: url,
                    type: 'GET',
                    beforeSend: (request) => {
                        if (this.getAuthToken()) {
                            request.setRequestHeader('Authorization', 'Token ' + this.getAuthToken());
                        }
                    }
                }).then((res) => {
                    return { id: obj.id, is_active: res.is_active };
                }).catch(() => {
                    return { id: obj.id, is_active: false };
                });
            });

            const results = await Promise.all(promises);
            results.forEach(item => {
                this.$set(this.activeCourses, item.id, item.is_active); // make reactive
            });
            self.$store.state.loadingScreen = false;
        },
        isActive(id) {
            return this.activeCourses[id]; // simple synchronous lookup
        },
        showCourseDetails: function(id, code=null) {
            if(this.$store.state.userType.toString() !== '2') {
                if (code) {
                    let url = ''
                    url = `https://www.fetchcourses.ie/course/finder?sfcw-courseId=${code}`;
                    let win = window.open(url, '_blank');
                    if (win) {
                        win.focus();
                    } else {
                        alert('Please allow popups for this website');
                    }
                }
                else {
                    this.$router.push('/course/' + id + '/');
                }
            }
        },
        getOffset: function(index) {
            if (index === 0) {
                return 0;
            }
            return 1;
        },
    },
}
</script>
<style>
    .college-card > .el-card__body {

        /*height: 100%;*/
    }
</style>
<style scoped>


    .college-card-title {
        font-size: calc(1.3vw + 1.3vh);
        line-height: 12vh;
        text-align: center;
        align-items: center;
        color: #000000;
        height: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }


    .yooni-fit-head-mobile-2 {
        padding: 1vw;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 500;
        color: #000000;
        text-align: justify !important;
    }

    .head-mobile-2 {
        font-size: calc(1.3vw + 1.2vh);
        line-height: calc(1.1vw + 2vh);
    }

    .course-div-mobile {
        width: 45vw;
        padding: 1.5vh 1vw;
        display: inline-table;
        height: 30vh;
    }

    .college-div-mobile {
        float: left;
        width: 100%;
        padding-bottom: 2vh;
    }

    .main-class {
        /*width: 100vw;*/
        overflow-x: auto;
        overflow-y: hidden;
        white-space: nowrap;
        margin: 0 0.5vw 0 0 !important;
    }
</style>
