<template>
    <el-row :gutter="50">
        <el-col :lg="15" :md="15" :sm="24" :xl="15" :xs="24">
            <el-row>
                <div class="popoverTitle main-title">
                    {{data.name}}
                </div>
            </el-row>
            <el-row>
                <div class="popover-desc">
                    {{data.ld}}
                </div>
            </el-row>
            <el-row style="margin: 2vh 0;">
                <el-col :xl="6" :lg="6" :md="8" :sm="24" :xs="24">
                    <el-button class="mobile-signupButton findoutmore-button"
                               type="button"
                               @click="$router.push('/filter?sector=' + data.name.replace('&', 'and'));">
                        Show all courses
                    </el-button>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :lg="15" :md="15" :sm="24" :xl="15" :xs="24" class="popoverTitle">
                    Who studies this subject
                </el-col>
                <el-col :span="9" v-if="!isMobile()">
                    <div class="sourcesDiv">
                        Sources: CSO and HEA
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24">
                    <el-row style="padding: 0 1vw;">
                        <el-col :span="6" class="barEndHeader" style="text-align: left;">
                            Male
                        </el-col>
                        <el-col :offset="12" :span="6" class="barEndHeader"
                                style="text-align: right;">
                            Female
                        </el-col>
                    </el-row>
                    <el-progress :percentage="data.male" :stroke-width="20" :text-inside="true"
                                 status="success" style="padding: 0 1vw;"></el-progress>
                </el-col>
                <el-col :lg="12" :md="12" :sm="24" :xl="12" :xs="24" style="padding: 0 1vw;">
                    <br v-if="isMobile()">
                    <el-row style="padding: 0 1vw;">
                        <el-col :span="6" class="barEndHeader" style="text-align: left;">
                            Full time
                        </el-col>
                        <el-col :offset="12" :span="6" class="barEndHeader" style="text-align: right;">
                            Part time
                        </el-col>
                    </el-row>
                    <el-progress :percentage="data.ft_emp_per" :stroke-width="20"
                                 :style="isMobile() ? {'margin-top': '10px'}:{}"
                                 :text-inside="true" status="success"
                                 style="padding: 0 1vw;"></el-progress>
                </el-col>
            </el-row>
            <el-row>
                <el-col v-if="isMobile()">
                    <div class="sourcesDiv-mobile">
                        Sources: CSO and HEA
                    </div>
                </el-col>
            </el-row>
            <el-row>
                <el-col :lg="15" :md="15" :sm="24" :xl="15" :xs="24" class="popoverTitle">
                    Related Jobs
                </el-col>
            </el-row>
            <el-row>
                <el-col :key="index+'1'" :lg="8" :md="8" :sm="12" :xl="8" :xs="12"
                        style="" v-for="(job, index) in data.careers">
                    <el-card style="padding: 1vh 1vw; margin-bottom: 1vh; margin-right: 1vw;">
                        <div>
                            <div class="jobName" style="font-size: medium" :title="job.career_title">
                                {{job.career_title}}
                            </div>
                            <div class="salary" style="font-size: small">
                                Salary €{{job.low_sal}}K - €{{job.high_sal}}K
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
        </el-col>
        <el-col :xl="9" :lg="9" :md="24" :sm="24" :xs="24" style="border-left: 1px solid lightgrey; height: 100%;">
            <el-row>
                <el-col class="popoverTitle">
                    Employers in this sector
                </el-col>
            </el-row>
            <el-row :key="employer.name" v-for="employer in data.employers" class="employerDetails">
                <div class="employerName" v-if="employer.logo !== ''"
                     @click="$router.push(`/company/${employer.short_name}/`)">
                    <img :src="publicPath + employer.logo" :alt="employer.name"/>
                </div>
                <div class="employerName" v-else>
                    {{employer.name}}
                </div>
            </el-row>
            <el-row>
                <el-col class="popoverTitle">
                    Employment Breakdown
                </el-col>
            </el-row>
            <el-row>
                <div class="popover-desc-2">
                    No. employed in sector: {{data.no_emp}}
                </div>
            </el-row>
            <div :style="!isMobile()?{width: '20vw', height: '300px'}:{width: '80vw', height: '300px'}">
                <div :style="!isMobile()?{width: '20vw', height: '300px'}:{width: '80vw', height: '300px'}"
                     id="doughnutChart"></div>
            </div>
            <el-row :key="index+'3'" style="text-align: left; width: 100%; margin-top: 3px; "
                    v-for="(obj, index) in data.cd[0]">
                <el-col :span="1">
                    <el-tag :color="pieChartColorPalette[index]" size="mini"></el-tag>
                </el-col>
                <el-col :span="22">
                    {{obj.name}}
                </el-col>
            </el-row>
        </el-col>

    </el-row>
</template>

<script>
  export default {
    name: 'CareerInfoPage',
    props: ['data'],
    mounted() {
      this.$nextTick(function() {
        let echarts = require('echarts');
        let dom = document.getElementById('doughnutChart');

        this.chartData.legend.data = this.data.cd[1];
        this.chartData.series[0].data = this.data.cd[0];
        this.chartData.series[0].color = this.pieChartColorPalette;
        let instance = echarts.init(dom, null, {renderer: 'svg'});
        instance.setOption(this.chartData);
      });

    },
    data: function() {
      return {
        pieChartColorPalette: [
          '#17234f',
          '#283d8a',
          '#3958c6',
          '#4d68cb',
          '#6179d1',
          '#788dd8',
          '#9cabe2',
          '#BABCE4',
          '#c4cdee',
          '#ebeef9',
        ],
        chartData: {
          tooltip: {
            // trigger: 'item',
            fontSize: 10,
            formatter: '{a} <br/>{b}: {d}%',
          },
          legend: {
            show: false,
            data: [],
          },
          series: [
            {
              name: '',
              color: [],
              type: 'pie',
              radius: ['50%', '80%'],
              center: ['50%', '50%'],
              avoidLabelOverlap: false,
              label: {
                show: false,
                position: 'center',
              },
              emphasis: {},
              labelLine: {
                show: false,
              },
              data: [],
            },
          ],
        },
      };
    },
    methods: {
      emitParentEvent: function() {
        this.$emit('closeDialog', false);
      },
    },
  };
</script>


<style scoped>

    .popover-desc {
        word-break: break-word;
        text-align: justify;
        font-family: Lato, serif;
        font-style: normal;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.8);
    }

    .sourcesDiv {
        text-align: right;
        font-size: 0.8vw;
        font-weight: 600;
        padding: 0.8vw 0;
        margin: 1vh 0;
    }

    .sourcesDiv-mobile {
        text-align: center;
        font-size: calc(0.8vw + 0.8vh);
        font-weight: 600;
        padding: calc(0.35vw + 0.25vh) 0;
        margin: 2vh 0;
    }

    .employerDetails {
        cursor: pointer;
        width: 100%;
        padding: 5px 0;
    }
</style>
